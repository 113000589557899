// Expose a function to the reCaptcha callback function
var reCaptchaFinancialPositionID = null
var reCaptchaApplicationOptionsID = null
var onReCaptchaLoadCallback = function() {
    // We need to handle 2 different recaptcha instances on the personal loan application
    console.log('Explicitly render reCaptcha')

    // reCaptcha for financial position
    const reCaptchaFinancialPositionElement = document.querySelector('.js-recaptcha-financial-position')
    reCaptchaFinancialPositionID = grecaptcha.render(reCaptchaFinancialPositionElement, {
        'sitekey': reCaptchaFinancialPositionElement.getAttribute('data-sitekey')
    })

    // reCaptcha for application options
    const reCaptchaApplicationOptionsElement = document.querySelector('.js-recaptcha-application-options')
    reCaptchaApplicationOptionsID = grecaptcha.render(reCaptchaApplicationOptionsElement, {
        'sitekey': reCaptchaApplicationOptionsElement.getAttribute('data-sitekey')
    })
}

if (personalLoan === undefined) var personalLoan = {}

personalLoan.app = (function () {
    var requestData = {
        Personal: true,
        GoodsType: '',
        CashPriceOfAsset: 0,
        TermsMonth: 0,
        PaymentFrequency: 'W',
        CashDeposit: 0,
        Make: '',
        Model: '',
        Year: '',
        ItemTypeVal: '',
        LoanTerm: '',
        ApplicationOptions: ''
    }

    function _startLoanApplication() {
        personalLoan.header.hideApplicationType()
        personalLoan.header.showMenu()
        personalLoan.header.setActiveMenu('your-details')
        personalLoan.router.go('your-details')

        _pushDataLayerPageEvent('Your details')
    }

    function _yourDetailsGoNext() {
        personalLoan.header.setActiveMenu('about-you')
        personalLoan.router.go('about-you')

        _pushDataLayerPageEvent('About you')
    }

    function _aboutYouGoNext() {
        personalLoan.header.setActiveMenu('employment-details')
        personalLoan.router.go('employment-details')

        _pushDataLayerPageEvent('Income')
    }

    function _employmentDetailsGoNext() {
        personalLoan.header.setActiveMenu('financial-position')
        personalLoan.router.go('financial-position')

        _pushDataLayerPageEvent('Financial position') 
    }

    function _applicationOptionsNext() {
        // Pass form data for application options section
        personalLoan.applicationOptions.populateApplicationOptionsInfo(window.form)

        personalLoan.header.setActiveMenu('application-options')
        personalLoan.router.go('application-options')

        _pushDataLayerPageEvent('Documents')
    }

    function _thanksYou(res) {
        personalLoan.header.setActiveMenu('thank-you')
        personalLoan.router.go('thank-you')

        _pushDataLayerPageEvent('Completed application')

        let name = `<strong>${$('#firstName').val()} ${$('#lastName').val()}</strong>`
        let coBorrowerName = `<strong>${$('#coborrowerfirstName').val()} ${$('#coborrowerlastName').val()}</strong>`

        if ($('#applicationSubType').val() === 'Joint') {
            name += ' and ' + coBorrowerName
        }
        $('#applicant-names').html(name);
        $('.loan-details').addClass('hidden-important');
        $('.btn-save-application').addClass('hidden');
        $('#navbarNav').removeClass().addClass('hidden-important');

        displayApplicationOptionsStatus(res)
        fadeInApplicationOptionsItem() 
    }

    function displayApplicationOptionsStatus(res) {
        // Handle submit form response, such as application options
        if (!!res) {
            // Form submitted with application options specified
            if (!!res.Data ) {
                const responseData = Object.keys(res.Data)

                if (!window.form) return
                
                const submittedIsJoint = window.form.IsJoint
                const submittedCheckedAplyID = window.form.IsCheckedAplyID
                const submittedCheckedThisIsBud = window.form.IsCheckedThisIsBud
                const submittedCoBorrowerIsCheckedAplyID = window.form.Personal.CoBorrowerIsCheckedAplyID
                const submittedCoBorrowerIsCheckedThisIsBud = window.form.Personal.CoBorrowerIsCheckedThisIsBud

                // Send event to GA
                if (submittedCheckedAplyID) {
                    window.dataLayer.push({'event': 'individual_APLYiD_submit'})
                }

                if (submittedCheckedThisIsBud) {
                    window.dataLayer.push({'event': 'individual_Bud_submit'})
                }

                if (submittedCoBorrowerIsCheckedAplyID) {
                    window.dataLayer.push({'event': 'individual_APLYiD_coborrower_submit'})
                }

                if (submittedCoBorrowerIsCheckedThisIsBud) {
                    window.dataLayer.push({'event': 'individual_bud_coborrower_submit'})
                }

                // Update thankyou page info based on Bud optin
                function updateThankYouBudInfo() {
                    const jsThankyouBud = document.querySelector('#js-thank-you-bud')
                    jsThankyouBud.innerHTML = 'We will use Bud to verify your financial information'
                }
              
                // Filter responseData based on form data
                function filterResponseData(isJoint, primaryAplyId, primaryBud, secondaryAlyid, secondaryBud, keys) {
                    let filterKeys = []
                    if (isJoint) {
                        keys.forEach(function(item) {                          
                            if (primaryAplyId && 
                                item.toLowerCase().trim().includes('primary') && 
                                item.toLowerCase().trim().includes('aplyid') && 
                                item.toLowerCase().trim().includes('status')) {
                                filterKeys.push(item)
                            } else if (primaryBud && 
                                item.toLowerCase().trim().includes('primary') && 
                                item.toLowerCase().trim().includes('bud') && 
                                item.toLowerCase().trim().includes('status')) {
                                filterKeys.push(item)
                                
                                updateThankYouBudInfo()
                            } else if (secondaryAlyid && 
                                item.toLowerCase().trim().includes('secondary') && 
                                item.toLowerCase().trim().includes('aplyid') && 
                                item.toLowerCase().trim().includes('status')) {
                                filterKeys.push(item)
                            } else if (secondaryBud && 
                                item.toLowerCase().trim().includes('secondary') && 
                                item.toLowerCase().trim().includes('bud') && 
                                item.toLowerCase().trim().includes('status')) {
                                filterKeys.push(item)

                                updateThankYouBudInfo()
                            }
                        })
                    } else {
                        keys.forEach(function(item) {                            
                            if (primaryAplyId && 
                                item.toLowerCase().trim().includes('primary') && 
                                item.toLowerCase().trim().includes('aplyid') && 
                                item.toLowerCase().trim().includes('status')) {
                                filterKeys.push(item)
                            } else if (primaryBud && 
                                item.toLowerCase().trim().includes('primary') && 
                                item.toLowerCase().trim().includes('bud') && 
                                item.toLowerCase().trim().includes('status')) {
                                filterKeys.push(item)

                                updateThankYouBudInfo()
                            }
                        })
                    }

                    return filterKeys
                }

                const thankYouHeading = document.querySelector('#thank-you-heading')

                for (let i of filterResponseData(submittedIsJoint, submittedCheckedAplyID, submittedCheckedThisIsBud, submittedCoBorrowerIsCheckedAplyID, submittedCoBorrowerIsCheckedThisIsBud, responseData).reverse()) {
                    // Get response data item detail
                    let submittedPrimaryFirstName = window.form['FirstName']

                    let submittedSecondaryFirstName = window.form.Personal['CoBorrowerFirstName']

                    let submittedApplicationOption = 'Bud'
                    if (i.toLowerCase().trim().includes('aplyid')) {
                        submittedApplicationOption = 'APLYiD'
                    }

                    let submittedPrimaryPhoneNumber = window.form['Phone']
                    let submittedPrimaryEmail = window.form['Email']

                    let submittedSecondaryPhoneNumber = window.form.Personal['CoBorrowerPhoneNumber']
                    let submittedSecondaryEmail = window.form.Personal['CoBorrowerEmail']

                    let htmlAfterHeading = `
                        ${
                            i.toLowerCase().trim().includes('primary') ? 
                            `<div class="application-options-res hidden-res">
                                ${
                                    !res.Data[i].Issuccess ? 
                                    `<div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fail-icon">
                                            <path d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                                        </svg>
                                    </div>

                                    <div class="res-details fail">
                                        <h3>${submittedPrimaryFirstName}, we seem to have had a bit of a hiccup connecting to ${submittedApplicationOption}.</h3>

                                        ${
                                            submittedApplicationOption === 'APLYiD' ? 
                                            `<p>
                                                Don't worry, one of our team will send you an SMS to verify your ID shortly.
                                            </p>`
                                            : 
                                            `<p>
                                                Don't worry, one of our team will send you a link to Bud shortly.
                                            </p>`
                                        }
                                    </div>`
                                    :
                                    `
                                    <div class="application-options-indicator-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="succeed-icon">
                                            <path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
                                        </svg>
                                    </div>
                                    
                                    <div class="res-details succeed">
                                        
                                        ${
                                            submittedApplicationOption === 'APLYiD' ? 
                                            `
                                            <h3>${submittedPrimaryFirstName}, ready to verify your ID with ${submittedApplicationOption}?</h3>
                                            <p>
                                                You will receive an SMS on ${submittedPrimaryPhoneNumber} with how to proceed shortly.
                                            </p>`
                                            : 
                                            `
                                            <h3>${submittedPrimaryFirstName}, ready to verify your income with ${submittedApplicationOption}?</h3>
                                            <p>
                                                You can start the income verification process <a href="${res.Data[i].URL}" target="_blank" rel="noreferrer noopener" id="ga4-continue-income-verification-primaryborrower">here</a> (this will open in a new window)
                                            </p>

                                            <p>
                                                If you're not ready, we've sent you an email to ${submittedPrimaryEmail} containing your unique link, which is valid for 24 hours.
                                            </p>`
                                        }
                                    </div>`   
                                }  
                            </div>` 
                            : 
                            `<div class="application-options-res hidden-res">
                                ${
                                    !res.Data[i].Issuccess ? 
                                    `<div>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="fail-icon">
                                            <path d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                                        </svg>
                                    </div>

                                    <div class="res-details fail">
                                        <h3>${submittedSecondaryFirstName}, we seem to have had a bit of a hiccup connecting to ${submittedApplicationOption}.</h3>
                                        ${
                                            submittedApplicationOption === 'APLYiD' ? 
                                            `<p>
                                                Don't worry, one of our team will send you an SMS to verify your ID shortly.
                                            </p>`
                                            : 
                                            `<p>
                                                Don't worry, one of our team will send you a link to Bud shortly.
                                            </p>`
                                        }
                                    </div>` 
                                    :
                                    `
                                    <div class="application-options-indicator-icon">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="succeed-icon">
                                            <path d="M11,15H13V17H11V15M11,7H13V13H11V7M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4A8,8 0 0,1 20,12A8,8 0 0,1 12,20Z" />
                                        </svg>
                                    </div>
                                    
                                    <div class="res-details succeed">
                                        
                                        ${
                                            submittedApplicationOption === 'APLYiD' ? 
                                            `
                                            <h3>${submittedSecondaryFirstName}, ready to verify your ID with ${submittedApplicationOption}?</h3>
                                            <p>
                                                You will receive an SMS on ${submittedSecondaryPhoneNumber} with how to proceed shortly.
                                            </p>`
                                            : 
                                            `
                                            <h3>${submittedSecondaryFirstName}, ready to verify your income with ${submittedApplicationOption}?</h3>
                                            <p>
                                                You can start the income verification process <a href="${res.Data[i].URL}" target="_blank" rel="noreferrer noopener" id="ga4-continue-income-verification-coborrower">here</a> (this will open in a new window)
                                            </p>

                                            <p>
                                                If you're not ready, we've sent you an email to ${submittedSecondaryEmail} containing your unique link, which is valid for 24 hours.
                                            </p>`
                                        }
                                        
                                    </div>`   
                                }  
                            </div>` 
                        }
                    `
                    thankYouHeading.insertAdjacentHTML("afterend", htmlAfterHeading)
                }
            }
        }
    }

    function fadeInApplicationOptionsItem() {
        const resItems = document.querySelectorAll('.hidden-res')

        resItems.forEach(function(item, index) {
            let timeItem = index + 1
            setTimeout(function() {
                item.classList.remove('hidden-res')
            }, timeItem * 150)
        })
    }

    function displayMessageModal() {
        const maskWrapper = document.querySelector('.mask-wrapper')

        let originalHTML = ``

        if (!!maskWrapper) {
            originalHTML = maskWrapper.innerHTML
            maskWrapper.classList.remove('hidden')
            maskWrapper.innerHTML = `
                <div class="response-message">
                    <div class="message-row">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                        </svg>
                        <div>
                            <h2>Upload Failed</h2>
                            <p>There was an error uploading your file. Please try again.</p>
                        </div>
                    </div>
                    <button class="response-message-button">Back to file upload</p>
                </div>
            `
        }

        const responseMessageButton = document.querySelector('.response-message-button')
        if (!!responseMessageButton) {
            responseMessageButton.addEventListener('click', function() {
                maskWrapper.classList.add('hidden')
                maskWrapper.innerHTML = originalHTML
            })
        }
    }

    // Based on the page title, push the event, but for the AA money don't include `personal/` in the URL
    function _pushDataLayerPageEvent(virtualPageTitle) {
        const isAAMoneyDealer = document.getElementById('isAAMoneyDealer').value === 'true'
        const pageTitleToPathMatcher = {
            'Your details': 'your-details',
            'About you': 'about-you',
            'Income': 'income',
            'Financial position': 'financial-position',
            'Documents': 'doc-upload',
            'Completed application': 'end-application'
        }
        
        // Check if virtualPageTitle is in the object, otherwise don't do anything
        if (pageTitleToPathMatcher[virtualPageTitle]) {
            let virtualPageURL = window.location.origin + '/personal/' + pageTitleToPathMatcher[virtualPageTitle]
            if (isAAMoneyDealer) {
                virtualPageURL = window.location.origin + window.location.pathname + '/' + pageTitleToPathMatcher[virtualPageTitle]
            }

            console.log('virtualPageURL', virtualPageURL)

            window.dataLayer.push({ 
                event: 'Pageview', 
                virtualPageURL: virtualPageURL,
                virtualPageTitle: virtualPageTitle
            })
        }
    }

    function _loanApplicationFinish(isSubmittingFromFinancialPositionStep = false) {
        // Add notes to external notes
        applyNoteOnSubmit()

        // Need to pass the recaptcha ID to the loan submit function based on the step
        // If the user is on the Financial position step, then we need to pass reCaptchaFinancialPositionID
        // If the user is on the Application options step, then we need to pass reCaptchaApplicationOptionsID
        let reCaptchaID = null
        let anyRecaptchaError = false
        if (isSubmittingFromFinancialPositionStep) {
            reCaptchaID = reCaptchaFinancialPositionID
            let recaptchaResponse = grecaptcha.getResponse(reCaptchaID)

            if (recaptchaResponse === '') {
                anyRecaptchaError = true
                $('.js-recaptcha-financial-position').find('iframe').attr('style', 'border: 1px solid red')
                $('.js-recaptcha-financial-position').parent().find('.error').attr('style', 'display: block')
            }
        } else {
            reCaptchaID = reCaptchaApplicationOptionsID
            let recaptchaResponse = grecaptcha.getResponse(reCaptchaID)

            if (recaptchaResponse === '') {
                anyRecaptchaError = true
                $('.js-recaptcha-application-options').find('div > iframe').attr('style', 'border: 1px solid red')
                $('.js-recaptcha-application-options').parent().find('.error').attr('style', 'display: block')
            }
        }

        if (anyRecaptchaError) {
            return
        }

        scrollToTop()
        personalLoan.goodsType.submitForm(reCaptchaID)
    }

    function applyNoteOnSubmit() {
        // Apply addtional note extra information here. Add a note of application options for UDC live
        if ($personalLoanForm.my('data').IsCheckedAplyID) {
            $personalLoanForm.my('data').Asset.AdditionalNotes += '\n The main applicant has applied for ApplyID'
        }

        if ($personalLoanForm.my('data').IsCheckedThisIsBud) {
            $personalLoanForm.my('data').Asset.AdditionalNotes += '\n The main applicant has applied for ThissisBud'
        }

        if ($personalLoanForm.my('data').Personal.CoBorrowerIsCheckedThisIsBud) {
            $personalLoanForm.my('data').Asset.AdditionalNotes += '\n The co-borrower has applied for ThissisBud'
        }
        
        if ($personalLoanForm.my('data').Personal.CoBorrowerIsCheckedAplyID) {
            $personalLoanForm.my('data').Asset.AdditionalNotes += '\n The co-borrower has applied for ApplyID'
        }
    }

    return {
        startLoanApplication: _startLoanApplication,
        yourDetailsGoNext: _yourDetailsGoNext,
        aboutYouGoNext: _aboutYouGoNext,
        employmentDetailsGoNext: _employmentDetailsGoNext,
        applicationOptionsNext: _applicationOptionsNext,
        thanksYou: _thanksYou,
        submitLoan: _loanApplicationFinish,
        requestData: requestData
    }
})();

jQuery(function () {
    /* Generic global event listeners */
    personalLoan.globalListeners.init()
    /* Start Application event listeners */
    $navbarNav = jQuery('#navbarNav')
    $termsAcceptance = jQuery('.js-terms-acceptance')
    $startApplicationButton = jQuery('.js-start-application')
    $yourDetailsGoNext = jQuery('.js-your-details-go-next')
    $aboutYouGoNext = jQuery('.js-about-you-go-next')
    $employmentDetailsGoNext = jQuery('.js-employment-details-go-next')
    $applicationOptionsNext = jQuery('.js-application-options-go-next')
    $financialPositionFinish = jQuery('.js-financial-position-finish')
    $financialPositionFinishDealer = jQuery('.js-financial-position-finish-dealer')
    $applicationOptionsFinish = jQuery('.js-application-options-finish')
    $btnAddPurchaseItem = jQuery('.js-add-purchase-item')
    $personalLoanForm = jQuery("#personal-loan")
    $savedForm = jQuery('#hdnform')

    const aaLoanType = $('#loanTypeAAMoney').val()
    if (aaLoanType !== 'aa-personal') {
        $personalLoanForm.my("data").AAPersonal = null
    }

    if ($savedForm.length !== 0) {
        personalLoan.goodsType.bindForm(JSON.parse($('#hdnform').val()));

        // We want to trigger the event only once per session, so we don't consider a simple page refresh as another application restart
        if (window.sessionStorage.getItem('applicationRestart') === null) {
            window.dataLayer.push({
                event: 'ApplicationRestart'
            })
            window.sessionStorage.setItem('applicationRestart', 'true')
        }
    }

    // toggle dropdown
    $('#dropdownMenuButton').on('click', function () {
        $(this).siblings('.dropdown-menu').toggleClass('show')
    })

    $termsAcceptance.on('click', function () {
        if ($termsAcceptance.is(':checked')) {
            $startApplicationButton
                .prop('disabled', false)
        } else {
            $startApplicationButton
                .removeClass('btn-primary')
                .addClass('btn-secondary')
                .prop('disabled', true)
        }
    })

    $startApplicationButton.on('click', function () {
        personalLoan.app.startLoanApplication()
        fixedAfListWidth() 
    })

    $yourDetailsGoNext.on('click', function () {
        if (personalLoan.goodsType.validateYourDetails()) {
            $('.btn-save-application').removeClass('hidden')
            
            personalLoan.app.yourDetailsGoNext()
            personalLoan.goodsType.saveForm(false)
        } else {
            onClickScrollToError($('div.content-wrapper[style*="display: block"] input[style*="border-color:red"]'), 0, 600)
        }
    })

    $aboutYouGoNext.on('click', function () {
        // TODO: find out why copyAddress is here, what does it do and probably change the name of the function to reflect better what it does as
        // it doesn't seem to be just copying the address
        personalLoan.goodsType.copyAddress()

        if (personalLoan.goodsType.validateAboutYou()) {
            personalLoan.app.aboutYouGoNext()
            personalLoan.goodsType.saveForm(false)
        } else {
            onClickScrollToError($('div.content-wrapper[style*="display: block"] input[style*="border-color:red"]'), 0, 600)
        }
    })

    $employmentDetailsGoNext.on('click', function () {
        if (personalLoan.goodsType.validateEmployeementDetails()) {
            $personalLoanForm.my("data").OtherIncomes = personalLoan.goodsType.getOtherIncomes();
            //$personalLoanForm.my("data", model);
            personalLoan.app.employmentDetailsGoNext();
            personalLoan.goodsType.saveForm(false);
        } else {
            onClickScrollToError($('div.content-wrapper[style*="display: block"] input[style*="border-color:red"]'), 0, 600)
        }
    })


    // Callback for personal application financial position next section (application options) and Dealer application financial position next section (Finish)
    const validateFinancialPositionAndNext = function (nextSection) {
        const isFormValid = personalLoan.goodsType.validateFinancialPosition()
        const $fields = $('.js-amount-validate')
        let list = []
 
        $fields.each(function (i) {
            const res = personalLoan.goodsType.validateAmount($(this))
            if (res !== undefined && !res)
                list.push(res);
        })

        if (list.length === 0 && isFormValid) {
            $personalLoanForm.my("data").OtherAssets = personalLoan.goodsType.getAssets()
            let sum = 0
            $personalLoanForm.my("data").OtherAssets.forEach(function (item) {
                sum += parseInt(item.Amount)
            })

            $personalLoanForm.my("data").Asset.OtherValue = parseFloat(sum)
            $personalLoanForm.my("data").Asset.OtherMonthlyPayment = personalLoan.goodsType.getOtherMonthlyPaymentSum()
            $personalLoanForm.my("data").Asset.OtherAmount = personalLoan.goodsType.getOtherAssetAmountSum()

            if (nextSection === 'applicationOptions') {
                personalLoan.app.applicationOptionsNext()
            } else {
                personalLoan.app.submitLoan()
            }
            
        } else {
            if ($('div.content-wrapper[style*="display: block"] input[style*="border-color:red"]').length !== 0) {
                onClickScrollToError($('div.content-wrapper[style*="display: block"] input[style*="border-color:red"]'), 0, 600)
            }
        }
    }

    // This will fire only on personal application UDC not Personal Dealer.
    $applicationOptionsNext.on('click', function () {
        validateFinancialPositionAndNext('applicationOptions')
    })

    $financialPositionFinish.on('click', function() {
        personalLoan.app.submitLoan(true)
    })
    
    $applicationOptionsFinish.on('click', function() {
        validateFilesBeforeSubmit()
    })
    

    // This needs to be different to personal UDC as it still needs to validate and does not go to the application options step.
    $financialPositionFinishDealer.on('click', function() {
        validateFinancialPositionAndNext('applicationFinish')
    })

    // Validate files before submit if there is any
    function validateFilesBeforeSubmit () {
        if (!!personalLoan.applicationOptions.uploadFormFilesArr && personalLoan.applicationOptions.uploadFormFilesArr.length > 0) {
            if (personalLoan.applicationOptions.uploadFormFilesArr.every(onSubmitValidateFileSizeFormat)) {
                scrollToTop()
                personalLoan.app.submitLoan()
            } else {
                displayMessageModal()
            } 
        } else {
            personalLoan.app.submitLoan()
        }
    }

    function onSubmitValidateFileSizeFormat(file) {
        const validFormats = ['image/jpeg', 'image/png', 'application/pdf']
        const validSize = 20
        const validLength = 100
        if (
            bytesToMegaBytes(file.size) > validSize ||
            !validFileFormat(validFormats, file.type) ||
            file.name.split('.').pop().toLowerCase() === 'jpeg' ||
            file.name.length > validLength 
            ) {
            return false
        } else {
            return true
        }
    
        function bytesToMegaBytes(bytes) {
          return (bytes / (1024 * 1024)).toFixed(2)
        }
    
        function validFileFormat(formatArray, type) {
          return formatArray.includes(type)
        }
    }

    function displayMessageModal() {
        const maskWrapper = document.querySelector('.mask-wrapper')

        let originalHTML = ``

        if (!!maskWrapper) {
            originalHTML = maskWrapper.innerHTML
            maskWrapper.classList.remove('hidden')
            maskWrapper.innerHTML = `
                <div class="response-message">
                    <div class="message-row">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                            <path d="M12,2L1,21H23M12,6L19.53,19H4.47M11,10V14H13V10M11,16V18H13V16" />
                        </svg>
                        <div>
                            <h2>Upload Failed</h2>
                            <p>There was an error uploading your file. Please try again.</p>
                        </div>
                    </div>
                    <button class="response-message-button">Back to file upload</p>
                </div>
            `
        }

        const responseMessageButton = document.querySelector('.response-message-button')
        if (!!responseMessageButton) {
            responseMessageButton.addEventListener('click', function() {
                maskWrapper.classList.add('hidden')
                maskWrapper.innerHTML = originalHTML
            })
        }
    }

    jQuery('#purchaseItems').html('')

    $btnAddPurchaseItem.on('click', () => personalLoan.goodsType.addPurchaseItem())

    $navbarNav.on('click', function (event) {
        const $clickedMenu = jQuery(event.target)
        if ($clickedMenu.hasClass('nav-link')) {
            switch ($clickedMenu.data().routeName) {
                case 'about-you':
                    if (personalLoan.goodsType.validateYourDetails()) {
                        personalLoan.app.yourDetailsGoNext()
                    }
                    break

                case 'employment-details':
                    if (personalLoan.goodsType.validateAboutYou()) {
                        personalLoan.app.aboutYouGoNext()
                    }
                    break

                case 'financial-position':
                    if (personalLoan.goodsType.validateEmployeementDetails()) {
                        personalLoan.app.employmentDetailsGoNext()
                    }
                    break

                case 'your-details':
                    personalLoan.app.startLoanApplication()
                    break

                case 'application-options':
                    if (personalLoan.goodsType.validateAboutYou() && 
                        personalLoan.goodsType.validateEmployeementDetails() && 
                        personalLoan.goodsType.validateFinancialPosition()) {
                        personalLoan.app.applicationOptionsNext()
                    }
                    break

                default:
                    break
            }
        }
    })

    jQuery('.js-finish-application').on('click', function () {
        personalLoan.header.setActiveMenu('thank-you')
        personalLoan.router.go('thank-you')
    })

    var afKey = jQuery('#afKey').val();
    var personalAddress;
    var postalAddress;
    var previousAddress;
    var coborrowerPersonalAddress;
    var coborrowerPostalAddress;
    var coborrowerPreviousAddress;

    var initAddressFinder = function () {
        personalAddress = new AddressFinder.Widget(
            document.getElementById('address'),
            afKey,
            'NZ', {
                "address_params": { "ascii": "1" },
            "location_params": { "ascii": "1" },
            "address_metadata_params": { "ascii": "1" },
            "show_locations": true}
        );
        postalAddress = new AddressFinder.Widget(
            document.getElementById('postAddress'),
            afKey,
            'NZ', {
                "address_params": { "ascii": "1" },
            "location_params": { "ascii": "1" },
            "address_metadata_params": { "ascii": "1" },
            "show_locations": true }
        );
        previousAddress = new AddressFinder.Widget(
            document.getElementById('previousAddress'),
            afKey,
            'NZ', {
                "address_params": { "ascii": "1" },
            "location_params": { "ascii": "1" },
            "address_metadata_params": { "ascii": "1" },
            "show_locations": true }
        );
        coborrowerPersonalAddress = new AddressFinder.Widget(
            document.getElementById('coborroweraddress'),
            afKey,
            'NZ', {
                "address_params": { "ascii": "1" },
            "location_params": { "ascii": "1" },
            "address_metadata_params": { "ascii": "1" },
            "show_locations": true }
        );
        coborrowerPostalAddress = new AddressFinder.Widget(
            document.getElementById('coBorrowerPostAddress'),
            afKey,
            'NZ', {
                "address_params": { "ascii": "1" },
            "location_params": { "ascii": "1" },
            "address_metadata_params": { "ascii": "1" },
            "show_locations": true}
        );
        coborrowerPreviousAddress = new AddressFinder.Widget(
            document.getElementById('coborrowerPreviousAddress'),
            afKey,
            'NZ', {
                "address_params": { "ascii": "1" },
            "location_params": { "ascii": "1" },
            "address_metadata_params": { "ascii": "1" },
            "show_locations": true }
        );
        personalAddress.on('result:select', function (fullAddress, metaData) {
            var selected = new AddressFinder.NZSelectedAddress(fullAddress, metaData);
            document.getElementById('address').value = selected.fullAddress;
            $('#city').val(selected.city()); $('#city').trigger('change');
            //$('#country').val('New Zealand'); $('#country').trigger('change');
            $('#country').parent().find('.option').each(function () {
                if ($(this).attr('country-value') == 'NZL') {
                    $(this).addClass('selected').trigger('click');
                }
            });
            $('#postcode').val(selected.postcode()); $('#postcode').trigger('change');
        });
        postalAddress.on('result:select', function (fullAddress, metaData) {
            var selected = new AddressFinder.NZSelectedAddress(fullAddress, metaData);
            document.getElementById('postAddress').value = selected.fullAddress;
            $('#postcity').val(selected.city()); $('#postcity').trigger('change');
            //$('#postcountry').val('New Zealand'); $('#postcountry').trigger('change');
            $('#postcountry').parent().find('.option').each(function () {
                if ($(this).attr('country-value') == 'NZL') {
                    $(this).addClass('selected').trigger('click');
                }
            });
            $('#postZipCode').val(selected.postcode()); $('#postZipCode').trigger('change');
        });
        previousAddress.on('result:select', function (fullAddress, metaData) {
            var selected = new AddressFinder.NZSelectedAddress(fullAddress, metaData);
            document.getElementById('previousAddress').value = selected.fullAddress;
            $('#previousCity').val(selected.city()); $('#previousCity').trigger('change');
            //$('#previousCountry').val('New Zealand'); $('#previousCountry').trigger('change');
            $('#previousCountry').parent().find('.option').each(function () {
                if ($(this).attr('country-value') == 'NZL') {
                    $(this).addClass('selected').trigger('click');
                }
            });
            $('#previousPostcode').val(selected.postcode()); $('#previousPostcode').trigger('change');
        });
        coborrowerPersonalAddress.on('result:select', function (fullAddress, metaData) {
            var selected = new AddressFinder.NZSelectedAddress(fullAddress, metaData);
            document.getElementById('coborroweraddress').value = selected.fullAddress;
            $('#coborrowercity').val(selected.city()); $('#coborrowercity').trigger('change');
            //$('#coborrowercountry').val('New Zealand');
            $('#coborrowercountry').parent().find('.option').each(function () {
                if ($(this).attr('country-value') == 'NZL') {
                    $(this).addClass('selected').trigger('click');
                }
            });
            $('#coborrowerpostcode').val(selected.postcode()); $('#coborrowerpostcode').trigger('change');
        });
        coborrowerPostalAddress.on('result:select', function (fullAddress, metaData) {
            var selected = new AddressFinder.NZSelectedAddress(fullAddress, metaData);
            document.getElementById('coBorrowerPostAddress').value = selected.fullAddress;
            $('#coBorrowerPostCity').val(selected.city()); $('#coBorrowerPostCity').trigger('change');
            //$('#coBorrowerPostCountry').val('New Zealand'); $('#coBorrowerPostCountry').trigger('change');
            $('#coBorrowerPostCountry').parent().find('.option').each(function () {
                if ($(this).attr('country-value') == 'NZL') {
                    $(this).addClass('selected').trigger('click');
                }
            });
            $('#coBorrowerPostZIP').val(selected.postcode()); $('#coBorrowerPostZIP').trigger('change');
        });
        coborrowerPreviousAddress.on('result:select', function (fullAddress, metaData) {
            var selected = new AddressFinder.NZSelectedAddress(fullAddress, metaData);
            document.getElementById('coborrowerPreviousAddress').value = selected.fullAddress;
            $('#coborrowerPreviousCity').val(selected.city()); $('#coborrowerPreviousCity').trigger('change');
            //$('#coborrowerPreviousCountry').val('New Zealand'); $('#coborrowerPreviousCountry').trigger('change');
            $('#coborrowerPreviousCountry').parent().find('.option').each(function () {
                if ($(this).attr('country-value') == 'NZL') {
                    $(this).addClass('selected').trigger('click');
                }
            });
            $('#coborrowerPreviousPostcode').val(selected.postcode()); $('#coborrowerPreviousPostcode').trigger('change');
        });
    };

    function downloadAddressFinder() {
        var script = document.createElement('script');
        script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
        script.async = true;
        script.onload = initAddressFinder;
        document.body.appendChild(script);
    };

    window.addEventListener('load', downloadAddressFinder())
})

function initTippy() {
    tippy('.js-tooltip', {
        trigger: 'mouseenter click',
    });
    tippy('.js-tooltip-dropdown');
}

// Mobile menu
jQuery(function () {
    $(".cross").hide();
    $(".menu").hide();
    $(".hamburger").on("click", function () {
        $(".menu").slideToggle("slow", function () {
            $(".hamburger").hide();
            $(".cross").show();
        });
    });

    $(".cross").on("click", function () {
        $(".menu").slideToggle("slow", function () {
            $(".cross").hide();
            $(".hamburger").show();
        });
    });
})

// format money
function formatMoney(num) {
    var str = num.toString().replace("$", ""), parts = false, output = [], i = 1, formatted = null;
    if (str.indexOf(".") > 0) {
        parts = str.split(".");
        str = parts[0];
    }
    str = str.split("").reverse();
    for (var j = 0, len = str.length; j < len; j++) {
        if (str[j] != ",") {
            output.push(str[j]);
            // if (i % 3 == 0 && j < (len - 1)) {
            //     output.push(",");
            // }
            i++;
        }
    }
    formatted = output.reverse().join("");
    return (formatted + ((parts) ? "." + parts[1].substr(0, 2) : ""));
};

// format money input. e.g 10000 -> 10,000
function formatMoneyControl(elem) {
    elem.on('keyup', function (e) {
        if(e.target.classList.contains('js-allow-zero')) {
            if (this.value.charAt(0) === '.') {
                $(this).val('')
            }
        } else {
            if (this.value.charAt(0) === '.' || this.value.charAt(0) === '0') {
                $(this).val('')
            }
        } 
        this.value = this.value.replace(/[^0-9\.|\,]/g, '')
        $(this).val(formatMoney($(this).val()))
    })
}

$(function () {
    function generateRandomString () {
        return Math.random().toString(36).substring(7) + Math.random().toString(36).substring(7)
    }

    const moneyControl = $('.js-money-control')
    const randomString = generateRandomString()

    formatMoneyControl(moneyControl)

    $('.card-head .card-icon').attr('src', '/resources/images/icons/car-monotone-white.png')

    // Disabled all input, that don't already have an autocomplete attribute, so we retain the ones we want to keep
    $('input:not([autocomplete])').attr('autocomplete', randomString)

    // Read a hidden input #disableAutoComplete to see if inputs inside #your-details-form-individual should have autocomplete disabled
    // Use a random string to disable autocomplete as the best way of doing it https://stackoverflow.com/a/34998771/23328884
    const isAAMoneyDealer = document.getElementById('isAAMoneyDealer').value === 'true'
    if ($('#hdnDealerId').val() !== '' && !isAAMoneyDealer) {
        console.log('Disabling Your Details section autocomplete')
        $('#your-details-form-individual input').attr('autocomplete', randomString)

        // Set name atrribute to random string, these seems the only way to disabled browser auto suggestion
        $('#firstName').attr('name', generateRandomString())
        $('#middleName').attr('name', generateRandomString())
        $('#lastName').attr('name', generateRandomString())
        $('#mobileNumber').attr('name', generateRandomString())
        $('#email').attr('name', generateRandomString())
    } 
    
    $('#your-details-form-individual').on('submit', (event) => event.preventDefault())
})

// Append new citizenship field
$('.form-wrapper .js-add-new-citizenship').on('click', addCitizenship);
$('.form-wrapper .js-citizenship-wrapper').on('click', '.remove-item', removeCitizenship);

function addCitizenship() {
    var html = $(this).closest('.form-wrapper').find('.js-citizenship-template').html();
    $(this).closest('.form-wrapper').find('.js-citizenship-template')
    $(this).closest('.form-wrapper').find('.js-citizenship-wrapper').append(html);
}

function removeCitizenship() {
    $(this).parent().parent().remove();
}

// Added for aa personal loan new form
$('.js-add-aa-personal-vehicle').on('click', addAAVehicle)
$('.js-aa-personal-vehicles-container').on('click', '.remove-item', removeAAVehicle)

function addAAVehicle() {
    var html = $('.js-add-aa-personal-vehicle-template').html()
    $('.js-aa-personal-vehicles-container').prepend(html)
}

function removeAAVehicle() {
    $(this).closest('.js-aa-personal-vehicle-wrapper').remove()
}

// checkbox event
$('.postalCheckbox').on("click", function () {
    $(this).parent().find('input').trigger('click');
    if ($(this).parent().find('input').prop('checked')) {
        $(this).addClass('checked');
    } else {
        $(this).removeClass('checked');
    }

    if ($(this).next().prop('checked')) {
        $(this).addClass('checked');
    } else {
        $(this).removeClass('checked');
    }
});

// use keyboard on dropdown menu
$(window).on('keyup', function (e) {
    var code = (e.keyCode ? e.keyCode : e.which);
    if (code == 9 && $('#loanTerm:focus').length) {

    }
});

// replace img for honda red theme 
function addHondaImage() {
    if ($('.honda-theme').length > 0) {
        $("img[src='/resources/images/logo.png']").eq(0).attr('src', '/resources/images/icons/088525-logo.png')
    }
}

function getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

// on next button click, scroll to first error in view when there is any
function onClickScrollToError(elem, elemIndex, time) {
    $('html, body').animate({
        scrollTop: elem.eq(elemIndex).offset().top - $(window).height()/2
    }, time);
}

addHondaImage();
initTippy();

// set address finder list width as address input box
function fixedAfListWidth() {
    const $addressInputBox = $('#address')
    const $afList = $('ul.af_list')

    $afList.css('max-width', `${$addressInputBox.width() + 20}px`)
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
}

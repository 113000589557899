// Source data
window.form = {
    Id: '',
    PreferredLoan: '',
    LoanTerm: '',
    PaymentFrequency: '',
    Title:'',
    TeslaReferenceNumber: '',
    AaMoneyMembershipNumber: '',
    FirstName: '',
    MiddleName: '',
    LastName: '',
    Phone: '',
    Email: '',
    DOB: '',
    MaritalStatus: '',
    DependantsNumber: 0,
    DependantsAge: '0',
    Address: '',
    City: '',
    ZIP: '',
    Country: '',
    ResidenceType:  '',
    PostAddress: '',
    PostCity: '',
    PostZIP: '',
    PostCountry: '',
    TimeAtAddressYears: 0,
    TimeAtAddressMonths: 0,
    PreviousAddress: '',
    PreviousCity: '',
    PreviousZIP: '',
    PreviousCountry: '',
    PreviousTimeAtAddressYears: 0,
    PreviousTimeAtAddressMonths: 0,
    NZResident: false,
    CountryOfBirth: '',
    CountryOfCitizenship: '',
    CountryOfAnotherCitizen: '',
    DriverLicenceType: '',
    DriverLicenceNumber: '',
    DriverLicenceVersion: '',
    ReuseForPostalAddress: true,
    IsCompleted: false,
    IsPersonal: true,
    DealerId: '',
    IsJoint: false,
    OtherIncome: '',
    Asset: {
        OwnHome: false,
        RentHome: false,
        MortgateValue: 0,
        MortgateBalance: 0,
        MortgateMonthlyPayment: 0,
        VehicleValue: 0.0,
        VehicleAmount: 0.0,
        VehicleMonthlyPayment: 0.0,
        FurnitureValue: 0,
        FurnitureAmount: 0,
        FurnitureMonthlyPayment: 0.0,
        PersonalLoanValue: 0.0,
        PersonalLoanAmount: 0.0,
        PersonalLoanMonthlyPayment: 0.0,
        CreditCardValue: 0,
        CreditCardAmount: 0,
        CreditCardMonthlyPayment: 0,
        OtherValue: 0.0,
        OtherAmount: 0.0,
        OtherMonthlyPayment: 0.0,
        AdditionalNotes: ''
    },
    Personal: {
        CompanyName: '',
        Occupation: '',
        EmploymentType: '',
        EmployerPhoneNumber: '',
        EmployerAddress: '',
        EmployerCity: '',
        EmployerZIP: '',
        EmployerCountry: '',
        TimeInThisJobYears: 0,
        TimeInThisJobMonths: 0,
        PreviousCompanyName: '',
        PreviousOccupation: '',
        PreviousEmploymentType: '',
        PreviousTimeInThisJobYears: 0,
        PreviousEmployer: '',
        CoBorrowerTitle: '',
        CoBorrowerFirstName: '',
        CoBorrowerMiddleName: '',
        CoBorrowerLastName: '',
        CoBorrowerPhoneNumber: '',
        CoBorrowerEmail: '',
        CoBorrowerDOB: '',
        CoBorrowerMaritalStatus: '',
        CoBorrowerDependantsNumber: 0,
        CoBorrowerDependantsAge: '0',
        CoBorrowerAddress: '',
        CoBorrowerCity: '',
        CoBorrowerZIP: '',
        CoBorrowerCountry: '',
        CoBorrowerResidenceType: '',
        CoBorrowerReuseForPostalAddress: true,
        CoBorrowerPostAddress: '',
        CoBorrowerPostCity: '',
        CoBorrowerPostZIP: '',
        CoBorrowerPostCountry: '',
        CoBorrowerTimeAtAddressYears: 0,
        CoBorrowerTimeAtAddressMonths: 0,
        CoBorrowerPreviousAddress: '',
        CoBorrowerPreviousCity: '',
        CoBorrowerPreviousZIP: '',
        CoBorrowerPreviousCountry: '',
        CoBorrowerPreviousTimeAtAddressYears: 0,
        CoBorrowerPreviousTimeAtAddressMonths: 0,
        CoBorrowerNZResident: false,
        CoBorrowerCountryOfBirth: '',
        CoBorrowerCountryOfCitizenship: '',
        CoBorrowerCountryOfAnotherCitizen: false,
        CoBorrowerDriverLicenceType: '',
        CoBorrowerDriverLicenceNumber: '',
        CoBorrowerDriverLicenceVersion: '',
        CoBorrowerCompanyName: '',
        CoBorrowerOccupation: '',
        CoBorrowerEmploymentType: '',
        CoBorrowerTimeInThisJobYears: '',

        CoBorrowerPreviousCompanyName: '',
        CoBorrowerPreviousOccupation: '',
        CoBorrowerPreviousEmploymentType: '',
        CoBorrowerPreviousTimeInThisJobYears: '',

        CoBorrowerOtherIncome: '',
        Income: 0,
        TakeHomePayFrequency: '',
        SpousePartnerIncome: 0,
        SpousePartnerFrequency: '',
        IncomeDecreaseExpected: false,
        IncomeDecreaseDetails: '',
        NameOfRelative: '',
        RelativeRelationshipToCustomer: '',
        RelativePhoneNumber: '',
        RelativeAddressLine1: '',
        RelativeAddressLine2: '',
        RelativeAddressLine3: '',

        CoBorrowerIsCheckedAplyID: false,
        CoBorrowerIsCheckedThisIsBud: false,

        NoPreviousEmployer: false,
        NoCoBorrowerPreviousEmployer: false,
    },
    PurchaseItems:
    {
        ItemType: '',
        Year: '',
        New: false,
        Used: false,
        PurchasePrice: 0,
        CashDeposit: 0,
        ItemTypeVal: '',
        Make: '',
        Model: '',
        LoanTerm: '',
        RepaymentFrequency: 'W'
    },
    OtherIncomes: [

    ],
    OtherAssets: [

    ],
    OtherFinancialCommitments: [

    ],
    IsCheckedAplyID: false,
    IsCheckedThisIsBud: false,
    AAPersonal: {
        PersonalLoanType:'',
        PersonalLoanReason:'',
        LoanForVehicle: false,
        DebtConsolidationReason :[],
        Vehicles:[]
    }
};

// Manifest
var manifest = {
    //Default data
    //data: { name: "", metrics: { age: "" } },

    // Init function
    //init: function ($node, runtime) {
    //    $node.html(
    //        '<h4>Simplest form</h4>' +
    //        '<div><input id="name" type="text" placeholder="Name"/></div>' +
    //        '<div><input id="age" type="number" placeholder="Age"/></div>'
    //    );
    //},

    // Bindings
    ui: {
        "#loanTerm": { bind: "LoanTerm" },
        "#loanTerm": { bind: "PreferredLoan" },
        "#repaymentFrequency": { bind: "PaymentFrequency" },
        "#title": { bind: "Title" },
        "#teslaReferenceNumber": { bind: "TeslaReferenceNumber" },
        "#aaMoneyMembershipNumber": { bind: "AaMoneyMembershipNumber" },
        "#firstName": { bind: "FirstName" },
        "#middleName": { bind: "MiddleName" },
        "#lastName": { bind: "LastName" },
        "#mobileNumber": { bind: "Phone" },
        "#email": { bind: "Email" },
        "#dateOfBirth": { bind: "DOB" },
        "#maritalStatus": { bind: "MaritalStatus" },
        "#dependants": {
            bind: function (data, value, $control) {
                if (value != null && value != '') data.DependantsNumber = parseFloat(value);
                //return data.DependantsNumber;
            }
        },
        "#driverLicenseType": { bind: "DriverLicenceType" },
        "#driverLicenseNumber": { bind: "DriverLicenceNumber" },
        "#driverLicenseVersion": { bind: "DriverLicenceVersion" },
        //"#nzResident": { bind: "NZResident" },
        "#nzResident": {
            bind: function (data, value, $control) {
                if (value != null) data.NZResident = value === "Yes";
            }
        },
        "#countryOfBirth": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.CountryOfBirth = dataVal;
                    }
                }
            }
        },
        "#countryOfCitizenship": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.CountryOfCitizenship = dataVal;
                    }
                }
            }
        },
        "#applicationSubType": {
            bind: function (data, value, $control) {
                if (value != null) data.IsJoint = value === "Joint";
            }
        },
        "#ddlotherIncome": { bind: "OtherIncome" },
        "#coborrowerddlotherIncome": { bind: "Personal.CoBorrowerOtherIncome" },
        //coborrower personal info

        "#coBorrowerTitle": { bind: "Personal.CoBorrowerTitle" },
        "#coborrowerfirstName": { bind: "Personal.CoBorrowerFirstName" },
        "#coborrowermiddleName": { bind: "Personal.CoBorrowerMiddleName" },
        "#coborrowerlastName": { bind: "Personal.CoBorrowerLastName" },
        "#coborrowermobileNumber": { bind: "Personal.CoBorrowerPhoneNumber" },
        "#coborroweremail": { bind: "Personal.CoBorrowerEmail" },
        "#coborrowerdateOfBirth": { bind: "Personal.CoBorrowerDOB" },
        "#coborrowermaritalStatus": { bind: "Personal.CoBorrowerMaritalStatus" },
        //"#coborrowerdependants": { bind: "Personal.CoBorrowerDependantsNumber" },
        "#coborrowerdependants": {
            bind: function (data, value, $control) {
                if (value != null && value != '') data.Personal.CoBorrowerDependantsNumber = parseFloat(value);
                //return data.Personal.CoBorrowerDependantsNumber;
            }
        },
        "#coborrowerdriverLicenseType": { bind: "Personal.CoBorrowerDriverLicenceType" },
        "#coborrowerdriverLicenseNumber": { bind: "Personal.CoBorrowerDriverLicenceNumber" },
        "#coborrowerdriverLicenseVersion": { bind: "Personal.CoBorrowerDriverLicenceVersion" },
        //"#coborrowernzResident": { bind: "Personal.CoBorrowerNZResident" },
        "#coborrowernzResident": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.CoBorrowerNZResident = value === "Yes";
            }
        },
        "#coborrowercountryOfBirth": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.Personal.CoBorrowerCountryOfBirth = dataVal;
                    }
                }
            }
        },
        "#coborrowercountryOfCitizenship": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.Personal.CoBorrowerCountryOfCitizenship = dataVal;
                    }
                }
            }
        },
        //"#coborrowertimeAtAddress": { bind: "Personal.CoBorrowerTimeAtAddressYears" },
        "#coborrowertimeAtAddressYears": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.CoBorrowerTimeAtAddressYears = parseFloat(value);
                return data.Personal.CoBorrowerTimeAtAddressYears;
            }
        },
        "#timeAtAddressYears": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.TimeAtAddressYears = parseFloat(value);
                return data.Personal.TimeAtAddressYears;
            }
        },
        "#paymentDuration": { bind: "Personal.TakeHomePayFrequency" },
        "#coborrowerpaymentDuration": { bind: "Personal.SpousePartnerFrequency" },
        "#SpousalPaymentDurationSingle": { bind: "Personal.SpousePartnerFrequency" },
        //"#Income": { bind: "Personal.Income" },
        "#Income": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace('$', '').replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Personal.Income = parseFloat(value);
                } else {
                    data.Personal.Income = 0;
                }
            }
        },
        //"#SpousalIncome": { bind: "Personal.SpousePartnerIncome" },
        "#SpousalIncome": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace('$', '').replace(/ /g, '').replace(/,/g, '') : value;
                if (data.IsJoint) {
                    if (value != null && parseFloat(value)) {
                        data.Personal.SpousePartnerIncome = parseFloat(value);
                    } else {
                        data.Personal.SpousePartnerIncome = 0;
                    }
                }
                
            }
        },
        "#SpousalIncomeSingle": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace('$', '').replace(/ /g, '').replace(/,/g, '') : value;
                if (!data.IsJoint) {
                    if (value != null && parseFloat(value)) {
                        data.Personal.SpousePartnerIncome = parseFloat(value);
                    } else {
                        data.Personal.SpousePartnerIncome = 0;
                    }
                }
            }
        },
        "#address": { bind: "Address" },
        "#city": { bind: "City" },
        "#postcode": { bind: "ZIP" },
        "#country": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.Country = dataVal;
                    }
                }
            }
        },
        "#residenceType": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('residence-type-value');
                    if (dataVal) {
                        data.ResidenceType = dataVal;
                    }
                }
            }
        },
        "#reuseForPostalAddress": {
            //bind: "ReuseForPostalAddress",
            bind: function (data, value, $control) {
                data.ReuseForPostalAddress = $control[0].checked
            }
        },
        "#coBorrowerReuseForPostalAddress": {
            //bind: "ReuseForPostalAddress",
            bind: function (data, value, $control) {
                data.Personal.CoBorrowerReuseForPostalAddress = $control[0].checked
            }
        },
        "#postAddress": { bind: "PostAddress" },
        "#postcity": { bind: "PostCity" },
        "#postZipCode": { bind: "PostZIP" },
        "#postcountry": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.PostCountry = dataVal;
                    }
                }
            }
        },
        "#timeAtAddressYears": { bind: "TimeAtAddressYears" },
        "#timeAtAddressMonths": { bind: "TimeAtAddressMonths" },

        "#previousAddress": { bind: "PreviousAddress" },
        "#previousCity": { bind: "PreviousCity" },
        "#previousPostcode": { bind: "PreviousZIP" },
        "#previousCountry": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.PreviousCountry = dataVal;
                    }
                }
            }
        },

        "#previousTimeAtAddressYears": { bind: "PreviousTimeAtAddressYears" },
        "#previousTimeAtAddressMonths": { bind: "PreviousTimeAtAddressMonths" },

        "#coborroweraddress": { bind: "Personal.CoBorrowerAddress" },
        "#coborrowercity": { bind: "Personal.CoBorrowerCity" },
        "#coborrowerpostcode": { bind: "Personal.CoBorrowerZIP" },
        "#coborrowercountry": {
            //bind: "Personal.CoBorrowerCountry",
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.Personal.CoBorrowerCountry = dataVal;
                    }
                }
            }
        },

        "#coborrowerResidenceType": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('residence-type-value');
                    if (dataVal) {
                        data.Personal.CoBorrowerResidenceType = dataVal;
                    }
                }
            }
        },

        "#coborrowertimeAtAddressYears": { bind: "Personal.CoBorrowerTimeAtAddressYears" },
        "#coborrowertimeAtAddressMonths": { bind: "Personal.CoBorrowerTimeAtAddressMonths" },

        "#coBorrowerPostAddress": { bind: "Personal.CoBorrowerPostAddress" },
        "#coBorrowerPostCity": { bind: "Personal.CoBorrowerPostCity" },
        "#coBorrowerPostZIP": { bind: "Personal.CoBorrowerPostZIP" },
        "#coBorrowerPostCountry": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.Personal.CoBorrowerPostCountry = dataVal;
                    }
                }
            }
        },

        "#coborrowerPreviousAddress": { bind: "Personal.CoBorrowerPreviousAddress" },
        "#coborrowerPreviousCity": { bind: "Personal.CoBorrowerPreviousCity" },
        "#coborrowerPreviousPostcode": { bind: "Personal.CoBorrowerPreviousZIP" },
        "#coborrowerPreviousCountry": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('country-value');
                    if (dataVal) {
                        data.Personal.CoBorrowerPreviousCountry = dataVal;
                    }
                }
            }
        },

        "#coborrowerPreviousTimeAtAddressYears": { bind: "Personal.CoBorrowerPreviousTimeAtAddressYears" },
        "#coborrowerPreviousTimeAtAddressMonths": { bind: "Personal.CoBorrowerPreviousTimeAtAddressMonths" },

        //employer info
        "#employerName": { bind: "Personal.CompanyName" },
        //"#occupation": { bind: "Personal.Occupation" },
        "#occupation": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('occupation-value');
                    if (dataVal && parseFloat(dataVal) != NaN) {
                        data.Personal.Occupation = dataVal;
                    }
                }
                //return data.Personal.Occupation;
            }
        },
        "#employmentType": { bind: "Personal.EmploymentType" },

        "#timeInJobYears": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.TimeInThisJobYears = parseFloat(value);
                //return data.Personal.TimeInThisJobYears;
            }
        },

        //previous employer info
        "#previousEmployerName": { bind: "Personal.PreviousCompanyName" },
        //"#occupation": { bind: "Personal.Occupation" },
        "#previousOccupation": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('occupation-value');
                    if (dataVal && parseFloat(dataVal) != NaN) {
                        data.Personal.PreviousOccupation = dataVal;
                    }
                }
                //return data.Personal.Occupation;
            }
        },
        "#previousEmploymentType": { bind: "Personal.PreviousEmploymentType" },

        "#previousTimeInJobYears": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.PreviousTimeInThisJobYears = parseFloat(value);
                //return data.Personal.TimeInThisJobYears;
            }
        },

        //co-borrower employer info
        "#coBorrowerEmployerName": { bind: "Personal.CoBorrowerCompanyName" },
        "#coBorrowerOccupation": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('occupation-value');
                    if (dataVal && parseFloat(dataVal) != NaN) {
                        data.Personal.CoBorrowerOccupation = dataVal;
                    }
                }
                //return data.Personal.Occupation;
            }
        },

        "#coBorrowerEmploymentType": { bind: "Personal.CoBorrowerEmploymentType" },

        "#coBorrowerTimeInJobYears": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.CoBorrowerTimeInThisJobYears = parseFloat(value);
                //return data.Personal.TimeInThisJobYears;
            }
        },

        //co-borrower previous employer info
        "#coBorrowerPreviousEmployerName": { bind: "Personal.CoBorrowerPreviousCompanyName" },
        //"#occupation": { bind: "Personal.Occupation" },
        "#coBorrowerPreviousOccupation": {
            bind: function (data, value, $control) {
                if (value != null) {
                    var dataVal = $control.parent().find('.option.selected').attr('occupation-value');
                    if (dataVal && parseFloat(dataVal) != NaN) {
                        data.Personal.CoBorrowerPreviousOccupation = dataVal;
                    }
                }
                //return data.Personal.Occupation;
            }
        },
        "#coBorrowerPreviousEmploymentType": { bind: "Personal.CoBorrowerPreviousEmploymentType" },

        "#coBorrowerPreviousTimeInJobYears": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.CoBorrowerPreviousTimeInThisJobYears = parseFloat(value);
                //return data.Personal.TimeInThisJobYears;
            }
        },
        
        "#incomeDecreaseExpected": {
            bind: function (data, value, $control) {
                if (value != null) data.Personal.IncomeDecreaseExpected = value === "Yes";
            }
        },

        "#incomeDecreaseDetails": { bind: "Personal.IncomeDecreaseDetails" },

        "#piItemType": { bind: "PurchaseItems.ItemType" },
        //"#piLoanAmount": { bind: "PurchaseItems.PurchasePrice" },
        "#piLoanAmount": { bind: "PurchaseItems.PurchasePrice" },
        "#piMake": { bind: "PurchaseItems.Make" },
        "#piModel": { bind: "PurchaseItems.Model" },
        "#piYear": { bind: "PurchaseItems.Year" },
        "#piLoanTerm": { bind: "PurchaseItems.LoanTerm" },
        "#piCashDeposit": { bind: "PurchaseItems.CashDeposit" },
        "#piRepaymentFrequency": { bind: "PurchaseItems.RepaymentFrequency" },

        // Asset
        "#rentOrOwn": {
            bind: function (data, value, $control) {
                if (value != null && value == 'Own') {
                    data.Asset.OwnHome = true;
                    data.Asset.RentHome = false;
                } else if (value != null && value == 'Rent') {
                    data.Asset.OwnHome = false;
                    data.Asset.RentHome = true;
                } else if (value != null && value == 'Neither') {
                    data.Asset.OwnHome = false;
                    data.Asset.RentHome = false;
                } else {
                    data.Asset.OwnHome = false;
                    data.Asset.RentHome = false;
                }
            }
        },
        "#mortgageAssetValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.MortgateValue = parseFloat(value);
                } else {
                    data.Asset.MortgateValue = 0;
                }
            }
        },
        //"#VehicleValue": { bind: "Asset.VehicleValue" },
        "#VehicleValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.VehicleValue = parseFloat(value);
                } else {
                    data.Asset.VehicleValue = 0;
                }
            }
        },
        "#FurnitureValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.FurnitureValue = parseFloat(value);
                } else {
                    data.Asset.FurnitureValue = 0;
                }
            }
        },
        //"#FurnitureValue": { bind: "Asset.FurnitureValue" },
        //"#CreditCardAmount": { bind: "Asset.CreditCardAmount" },
        "#CreditCardAmount": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.CreditCardAmount = parseFloat(value);
                    //data.Asset.CreditCardMonthlyPayment = parseFloat(value) * 0.05;
                } else {
                    data.Asset.CreditCardAmount = 0;
                    //data.Asset.CreditCardMonthlyPayment = 0;
                }
            }
        },
        "#CreditCardMonthlyPayment": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.CreditCardMonthlyPayment = parseFloat(value);
                } else {
                    data.Asset.CreditCardMonthlyPayment = 0;
                }
            }
        },
        "#mortgageAssetBalance": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.MortgateBalance = parseFloat(value);
                } else {
                    data.Asset.MortgateBalance = 0;
                }
            }
        },
        //"#mortgageAssetBalance": { bind: "Asset.MortgateBalance" },
        //"#mortgageAssetMonthlyPayments": { bind: "Asset.MortgateMonthlyPayment" },
        "#mortgageAssetMonthlyPayments": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.MortgateMonthlyPayment = parseFloat(value);
                } else {
                    data.Asset.MortgateMonthlyPayment = 0;
                }
            }
        },
        "#mortgageRentMonthlyPayments": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.MortgageRentPaymentAmount = parseFloat(value);
                } else {
                    data.Asset.MortgageRentPaymentAmount = 0;
                }
            }
        },
        //"#PersonalLoanAmount": { bind: "Asset.PersonalLoanAmount" },
        "#PersonalLoanAmount": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.PersonalLoanAmount = parseFloat(value);
                } else {
                    data.Asset.PersonalLoanAmount = 0;
                }
            }
        },
        //"#PersonalLoanMonthlyPayment": { bind: "Asset.PersonalLoanMonthlyPayment" },
        "#PersonalLoanMonthlyPayment": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.PersonalLoanMonthlyPayment = parseFloat(value);
                } else {
                    data.Asset.PersonalLoanMonthlyPayment = 0;
                }
            }
        },
        
        "#ratesExpenditureValueFrequency": {
            bind: function (data, value, $control) {
                data.Asset.CouncilRatesExpenditureValueFrequency = value;
            }
        },
        "#ratesExpenditureValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.CouncilRatesExpenditureValue = parseFloat(value);
                } else {
                    data.Asset.CouncilRatesExpenditureValue = 0;
                }
            }
        },

        "#insuranceExpenditureValueFrequency": {
            bind: function (data, value, $control) {
                data.Asset.HomeInsuranceExpenditureValueFrequency = value;
            }
        },
        "#insuranceExpenditureValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.HomeInsuranceExpenditureValue = parseFloat(value);
                } else {
                    data.Asset.HomeInsuranceExpenditureValue = 0;
                }
            }
        },

        "#utilitiesExpenditureValueFrequency": {
            bind: function (data, value, $control) {
                data.Asset.UtilitiesExpenditureValueFrequency = value;
            }
        },
        "#utilitiesExpenditureValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.UtilitiesExpenditureValue = parseFloat(value);
                } else {
                    data.Asset.UtilitiesExpenditureValue = 0;
                }
            }
        },

        "#livingExpenditureValueFrequency": {
            bind: function (data, value, $control) {
                data.Asset.LivingExpenditureValueFrequency = value;
            }
        },
        "#livingExpenditureValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.LivingExpenditureValue = parseFloat(value);
                } else {
                    data.Asset.LivingExpenditureValue = 0;
                }
            }
        },

        "#vehicleExpenditureValueFrequency": {
            bind: function (data, value, $control) {
                data.Asset.VehicleExpenditureValueFrequency = value;
            }
        },
        "#vehicleExpenditureValue": {
            bind: function (data, value, $control) {
                value = (value) ? value.replace(/ /g, '').replace(/,/g, '') : value;
                if (value != null && parseFloat(value)) {
                    data.Asset.VehicleExpenditureValue = parseFloat(value);
                } else {
                    data.Asset.VehicleExpenditureValue = 0;
                }
            }
        },
        "#additionalNotes": { bind: "Asset.AdditionalNotes" },
    },
    //PurchaseItemsForm: {
    //    ui: {
    //        "#piItemType": "ItemType",
    //        "#piLoanAmount": "PurchasePrice",
    //        "#piMake": "Make",
    //        "#piModel": "Model",
    //        "#piLoanTerm": "LoanTerm",
    //        "#piCashDeposit": "CashDeposit",
    //        "#piRepaymentFrequency": "RepaymentFrequency"
    //    }
    //},
};

// Init $.my over DOM node
$(".personal-loan").eq(0).my(manifest, form);
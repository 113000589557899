if (window.personalLoan === undefined) window.personalLoan = {}

window.personalLoan.router = (function () {
    function _go (routeName) {
        window.parent.postMessage('UDCviewChange', '*')
        
        jQuery('[data-route]').hide()
        jQuery('[data-route]').removeClass('is-active-route')
        jQuery('[data-route="' + routeName + '"]').show()
        jQuery('[data-route="' + routeName + '"]').addClass('is-active-route')
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }
    
    return {
        go: _go
    }
})();
